export default {
  colors: {
    primary: `#FB8500`,
    background: `#023047`,
    shape: `#F2F2FA`,
    title: `#41a5a4`,
    text: `#fff`,
    components: {
      blockquote: {
        background: `#41a5a4`,
        text: `#2d3748`,
      },
    },
  },
};
